import React, {
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
// Assets
import { QRCaducado } from "../../assets";
// Components
import QRCode from "react-qr-code";
import { Button, Card, Layout, Spinner } from "../../components";
import { LinearProgress } from "@mui/material";
// Servicios
import {
    clienteUsuarioGet,
    loginQREvent,
    loginQRGet,
    usuarioMinimosGet,
} from "../../services";
import { GlobalContext } from "../../Context";
import { useNavigate } from "react-router-dom";

export default function QR() {
    const { merchant, setToastInfo } = useContext(GlobalContext);
    const navigate = useNavigate()

    const [UUID, setUUID] = useState();
    const [loading, setLoading] = useState(true);
    const [expirado, setExpirado] = useState(false);
    const [coord, setCoord] = useState();
    const [progress, setProgress] = useState(0);
    const intentos = useRef(0);

    const handleError = useCallback(
        (text) => {
            setToastInfo({ open: true, text });
            setLoading(false);
        },
        [setToastInfo]
    );

    const getQR = useCallback(async () => {
        if (!coord) return;
        setExpirado(false);
        setLoading(true);
        const data = await loginQRGet(
            coord.lat.toFixed(6),
            coord.lng.toFixed(6)
        );
        if (!data) return handleError("Ha ocurrido un error inesperado");
        setProgress(0);
        setUUID(data.codigoQR);
        setLoading(false);
    }, [coord, handleError]);

    const getQREvent = useCallback(async () => {
        if (!UUID) return;

        const response = await loginQREvent(UUID);

        if (response.status.code === 1 && merchant) {
            const user = await usuarioMinimosGet(response.result[0]);
            const usuario = (
                await clienteUsuarioGet(
                    merchant.merchantId,
                    null,
                    user.usuarioId
                )
            ).filter((u) => u.relacionStatus === "Activo")[0];
            if (usuario) {
                navigate('/loading')
            } else {
                handleError('Usuario no relacionado con el merchant')
            }
        }
        intentos.current++;
        setUUID(null);
        intentos.current < 2 ? getQR() : setExpirado(true);
    }, [getQR, UUID, merchant, navigate]);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (data) => {
                setCoord({
                    lat: data.coords.latitude,
                    lng: data.coords.longitude,
                });
            },
            (error) => console.log(error)
        );

        const timer = setInterval(() => {
            setProgress((oldProgress) =>
                oldProgress + 60 / 580 >= 100
                    ? 100
                    : oldProgress + ((60 / 580) * 100) / 60
            );
        }, (60 / 580) * 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        getQR();
    }, [getQR]);

    useEffect(() => {
        getQREvent();
    }, [getQREvent]);

    if (loading)
        return (
            <Layout>
                <Card color="blue">
                    <Spinner />
                </Card>
            </Layout>
        );

    return (
        <Layout>
            <Card color="blue">
                {expirado ? (
                    <>
                        <h2>¡Ups! El código QR caducó</h2>
                        <p>
                        Por favor, genera un nuevo código QR para poder ingresar
                        </p>
                        <img src={QRCaducado} alt="QR caduco" />
                        <Button type="primary" color="orange-strong" onClick={getQR}>
                            Generar QR
                        </Button>
                    </>
                ) : (
                    <>
                        <h2>¡Bienvenido!</h2>
                        <p className="blue">
                            Para poder ingresar, escanea el QR desde la App de
                            MIA.
                        </p>
                        {UUID && (
                            <>
                                <QRCode value={UUID} color={"#123787"} className="qr"/>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    style={{
                                        width: "100%",
                                        marginTop: "16px",
                                        color: "#123787",
                                    }}
                                />
                                <p className="disclaimer">Tiempo restante para scanear el QR</p>
                            </>
                        )}
                    </>
                )}
            </Card>
        </Layout>
    );
}
