import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Layout } from "../../components";
import './Home.scss';
import { WebexIcon } from "../../assets";

export default function Home() {
    return (
        <Layout header={false} id="home">
            <Card>
                <img src={WebexIcon} alt="Webex" className="webex"/>
                <Button type="primary" color="black">
                    <Link to="/email">Iniciar sesión</Link>
                </Button>
                <Button type="secondary" color="black">
                    Entrar a una reunión
                </Button>
                <p className="register">Crear una cuenta nueva</p>
            </Card>
            <footer className="footer">
                Al utilizar Webex, acepta Condiciones del servicio, Declaracion de privacidad, Aviso y descargo. Obtenga más información sobre Webex.
            </footer>
        </Layout>
    );
}
