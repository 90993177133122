import React from "react";

import classes from "./HeaderIdentity.module.scss";
import { IsologoHeaderIdentity, LogoMiaNew } from "../../assets";

export default function HeaderIdentity() {
    return (
        <header className={classes.header}>
            <div className={classes.containerMiaLogo}>
                <img
                    src={LogoMiaNew}
                    alt="new logo"
                    className={classes.newLogoMia}
                />
            </div>
            <img
                src={IsologoHeaderIdentity}
                alt="isologo"
                className={classes.isologo}
            />
        </header>
    );
}
