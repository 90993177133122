import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import { Email, Home, Loading, Login, QR, Webex } from "./pages";
import { Toast } from "./components";

export default function App() {
  return (
    <Router>
      <Toast />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/email" element={<Email />} />
        <Route path="/login" element={<Login />} />
        <Route path="/qr" element={<QR />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/webex" element={<Webex />} />
      </Routes>
    </Router>
  );
}
