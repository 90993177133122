import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, Layout } from "../../components";
import './Email.scss';
import { ButtonMIA, WebexIcon } from "../../assets";

export default function Home() {
    return (
        <Layout header={false} id="email">
            <Card>
                <img src={WebexIcon} alt="Webex" className="webex" />
                <h1>Iniciar sesión</h1>
                <p>Comience por introducir su dirección de correo electronico.</p>
                <input type="text" placeholder="Dirección de correo" className="input"/>
                <Button type="primary" color="black" disabled={true}>
                    Siguiente
                </Button>
                <Link to="/qr">
                    <img src={ButtonMIA} alt="mia" className="button-mia"/>
                </Link>
            </Card>
        </Layout>
    );
}
