import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../Context";
import "./Toast.scss";

export default function Toast() {
    const {
        toastInfo: { open, text },
        setToastInfo,
    } = useContext(GlobalContext);

    useEffect(() => {
        if (!open) return;
        setTimeout(() => {
            setToastInfo({ open: false, text: "" });
        }, 5000);
    }, [open, setToastInfo]);

    return (
        <div className={`toast ${open ? "open" : ""}`}>
            {text}
        </div>
    );
}
