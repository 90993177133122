import React from "react";
import classes from "./Layout.module.scss";
import { HeaderIdentity } from '../index'

export default function Layout({children, header = true, id}) {
    return (
        <>
            {header && <HeaderIdentity />}
            <main className={classes.main} id={id}>
                <div className={classes.cardContainer}>{children}</div>
            </main>
        </>
    );
}
