import axiosPost from "./axiosPost";

export const loginQREvent = async (qr) => {
    const body = {
        service: "LoginQREvent",
        params: {
            codigoQR: qr,
        },
    };
    const result = await axiosPost(body);
    return result;
};
