import axiosPost from "./axiosPost";

export const clienteUsuarioGet = async (
    merchantId,
    tipoRelacion,
    usuarioId = null
) => {
    const body = {
        service: "MIADemoClienteUsuarioGet",
        params: {
            merchantId: merchantId,
            merchantServicioUsuarioTiporel: tipoRelacion,
            usuarioId: usuarioId,
        },
    };

    const result = await axiosPost(body);
    return result.result;
};
