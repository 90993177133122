import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [merchant, setMerchant] = useState({
        merchantId: 132,
        merchantNombre: "Moov",
        merchantRZ: "Moov",
    });
    const [toastInfo, setToastInfo] = useState({ open: false, text: "" });
    const providerValue = {
        merchant,
        setMerchant,
        toastInfo,
        setToastInfo,
    };

    return (
        <GlobalContext.Provider value={providerValue}>
            {children}
        </GlobalContext.Provider>
    );
};
