import React from "react";
import './Button.scss';

export default function Button({
    type,
    color,
    text,
    children,
    onClick,
    customClass,
    disabled
}) {
    const className = `button ${type} ${color} ${customClass}`;

    return (
        <button className={className} onClick={onClick} disabled={disabled}>
            {text || children}
        </button>
    );
}
