import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Layout } from "../../components";
import './Loading.scss';
import { WebexIcon, Spinner } from "../../assets";
import { useEffect } from "react";

export default function Loading() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/webex')
        }, 3000)
    }, [])
    return (
        <Layout header={false} id="loading">
            <Card>
                <img src={WebexIcon} alt="Webex" className="webex"/>
                <h1>Iniciando sesión...</h1>
                <img src={Spinner} alt="" className="spinner"/>
            </Card>
        </Layout>
    );
}
