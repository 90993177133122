import React from "react";
import './Card.scss';

export default function Card({ color, children }) {
    return (
        <div className={`card ${color}`}>
            {children}
        </div>
    );
}
