import React from 'react';
import './Webex.scss'
import { PantallaWebex } from '../../assets';

export default function Webex() {
    return (
        <>
            <div className='white'></div>
            <div className='white2'></div>
            <div className='gray'></div>
            <img src={PantallaWebex} alt="" className='webex-image' />
        </>
    )
}