import axios from "axios";

const serviceURL = "https://rp.hooli.la/hooli-rp/RPServices";

export default async function axiosPost(body, credentials = false) {
    const headers = {
        "Content-Type": "application/json",
    };

    if (credentials) {
        headers["x-hooli-token"] = credentials.hooliToken || credentials.token;
        headers["x-session-id"] = credentials.sessionId;
    }
    
    try {
        const result = await (
            await axios.post(serviceURL, body, { headers })
        ).data;
        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: "Error inesperado en request",
                    action: "U",
                    id: null,
                },
                result: [],
            };
        }
        return result;
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: "U",
                id: null,
            },
            result: [],
        };
    }
}
